import { cssVar } from '@chakra-ui/theme-tools';
import { transitions } from '../../theme/motion.theme';

// Define the CSS variables that Chakra uses internally for tabs
const $tabsColor = cssVar('tabs-color');
const $selectedColor = cssVar('tabs-selected-color');

export const Tabs = {
  baseStyle: {
    tab: {
      [$tabsColor.variable]: 'colors.content.primary',
      [$selectedColor.variable]: 'colors.content.text.button',

      borderBottom: '2px solid',
      borderColor: 'transparent',
      transition: transitions.tabHover,

      _selected: {
        color: $selectedColor.reference,
      },
    },

    tabpanel: {
      padding: 4,
    },
  },

  variants: {
    enclosed: {
      tab: {
        _selected: {
          color: 'gray.800',
          bg: 'white',
          border: 'none',
          borderTopRadius: '4px',
          position: 'relative',
        },
        color: 'content.primary',
        bg: 'surface.tertiary',
        borderBottom: 'none',
        borderTopRadius: '4px',
        fontSize: '6',
        fontWeight: '500',
        height: '32px',
        px: '3',
        mr: '4px',
        mb: '-1px',
        border: 'none',
      },
      tabpanels: {
        borderTop: '1px solid',
        borderColor: 'gray.100',
        backgroundColor: 'white',
      },
    },
  },

  defaultProps: {
    colorScheme: 'color-tabs-primary',
  },
};

export default Tabs;
