const transitionEasing = {
  entranceTransition: 'cubic-bezier(0.5, 0.5, 0, 1)',
  exit: 'cubic-bezier(1, 0, 0.5, 0.5)',
};

const transitionDuration = {
  short: '0.015s', // 150ms
  quick: '0.03s', // 300ms
  subtle: '0.05s', // 500ms
  gentle: '0.1s', // 1000ms
};

// Reusable transition strings for direct use in component styles
export const transitions = {
  // Button transitions
  buttonHover: `all ${transitionDuration.short} ${transitionEasing.entranceTransition}`,

  // Tab transitions
  tabHover: `all ${transitionDuration.short} ${transitionEasing.entranceTransition}`,
  tabClick: `all ${transitionDuration.quick} ${transitionEasing.entranceTransition}`,

  // Segmented control transitions
  segmentedControlHover: `all ${transitionDuration.short} ${transitionEasing.entranceTransition}`,

  // Pop-in animations
  popInEntry: `all ${transitionDuration.subtle} ${transitionEasing.entranceTransition}`,
  popInExit: `all ${transitionDuration.subtle} ${transitionEasing.exit}`,

  // Dashboard interactions
  dashboardHoverEntry: `all ${transitionDuration.gentle} ${transitionEasing.entranceTransition}`,
  dashboardHoverExit: `all ${transitionDuration.gentle} ${transitionEasing.exit}`,

  // Celebration animation
  celebrationEntry: `all ${transitionDuration.gentle} ${transitionEasing.entranceTransition}`,

  // Sidebar interactions
  sidebarEntry: `all ${transitionDuration.subtle} ${transitionEasing.entranceTransition}`,

  // Dropdown transitions
  dropdownEntry: `all ${transitionDuration.subtle} ${transitionEasing.entranceTransition}`,
  dropdownExit: `all ${transitionDuration.quick} ${transitionEasing.exit}`,
};

// Keyframes for animations (if needed)
export const keyframes = {
  popIn: {
    '0%': { transform: 'scale(0.8)', opacity: 0 },
    '100%': { transform: 'scale(1)', opacity: 1 },
  },
};

// Export the complete motion theme
export const motionTheme = {
  transitions,
  keyframes,
};
