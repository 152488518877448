import { transitions } from '../../theme/motion.theme';

export const Menu = {
  baseStyle: {
    list: {
      padding: '0',
      bg: 'surface.primary',
      border: '1px solid',
      borderColor: 'stroke.primary',
      borderRadius: 'base',
      transition: transitions.dropdownEntry,
      '& > :first-of-type': {
        borderTopRightRadius: 'base',
        borderTopLeftRadius: 'base',
      },
      '& > :last-child': {
        borderBottomRightRadius: 'base',
        borderBottomLeftRadius: 'base',
      },
    },
    groupTitle: {
      cursor: 'default',
      margin: '0',
      py: 'mg2',
      px: 3,
      fontSize: 6,
      fontWeight: 6,
      lineHeight: 6,
      color: 'content.primary',
    },
    item: {
      py: 'mg2',
      px: 3,
      color: 'content.primary',
      display: 'flex',
      fontSize: 6,
      justifyContent: 'space-between',
      bg: 'surface.primary',
      transition: transitions.buttonHover,
      _active: {
        bg: 'surface.secondary',
        color: 'content.primary',
      },
      _hover: {
        boxShadow: 'none',
        bg: 'surface.secondary',
      },
      _disabled: {
        color: 'content.action.disabled',
      },
      // overriding chakra defaults
      '.chakra-menu__icon-wrapper': {
        marginEnd: 2,
      },
    },
  },
};
